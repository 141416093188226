// keywords.js
module.exports = {
    primary: [
        "big tits", "huge tits", "mature sex", "mature tube", "blowjobs", "xvidoes",
        "spank bang", "xhamester", "www xmxx com", "xmxx com", "xnnx com", "xnnxx",
        "xnxc", "xxxvedios", "you potn", "yporn", "xhamster live", "reallifecams",
        "hqporner", "pron hub", "sxyprn", "eporner", "imagefap", "berazerz",
        "pirnhub", "redtubr", "tube galore", "free sex", "motherless", "leolist",
        "sexgif", "xham", "redtube", "porndude", "youjizz", "xxnx", "multporn",
        "thothub", "brazzers", "ixxx", "pormhub", "tube8", "damplip", "hentaimanga",
        "jav guru", "luxure tv", "mia khalifa", "nhentai", "porngif", "prnhub"
    ],
    secondary: [
        "b tits", "big titia", "bigstits", "tits", "bigboobs", "big big boob",
        "big booboes", "bigtitsteen", "huge boobs natural", "huge huge tits",
        "bkow job", "blou job", "blowjo b", "blowjobbs", "blowjobss", "blowjoobs",
        "blwo job", "bj pov", "bestblow job", "blow j", "blowjobgif", "blowjobpov",
        "deepthroating", "motherkess", "motherliess", "motherlwaa", "leo list",
        "x vido", "xhmaster", "cxnxx", "pohub", "porh hub", "sexvidios", "xncx",
        "xnxxc", "xnxxn", "xnxz", "xnzx", "youoorn", "youporm", "znxx", "xxxvides",
        "xxxvidoes", "redtubw"
    ],
    longTail: [
        "pornh", "pornhoarder", "lushstories", "youjiz", "pornhits", "snapchat nudes",
        "xnxxx", "gianna michaels", "sasha grey", "porm", "dropmms", "handjob",
        "free pirn", "xporn", "momswap", "sparkbang", "hardcore sex", "pornstars",
        "yespornplease", "xnnx", "mrdeepfake", "shesfreaky", "double penetration",
        "pornzog", "alyx star", "sexx", "nude pics", "xvedio", "mom sex", "pornkai",
        "eva lovia", "xmxx", "porntrex", "cfnm", "nikki benz", "facesitting",
        "natasha nice", "gloryholeswallow", "fkbae", "xvidio", "uporn", "nicolette shea",
        "porn00", "milf300", "pornyub", "elsa jean", "potnhub", "xxxnx", "freshporno",
        "shooshtime", "hentairead", "jenna jameson", "ava addams", "poenhub",
        "sexcom", "pornhat", "hentia", "hard sex", "lulu chu", "hentaifox", "xxnxx",
        "cory chase", "purnhub", "nina hartley", "spangbang", "8muses", "3 movs",
        "voyeur house", "scarlettkissesxo", "jesse jane", "aletta ocean", "alexis fawx",
        "alexis texas", "aloha tube", "adriana chechik", "4tube", "alohatube",
        "alura jenson", "anissa kate", "ape tube", "arab sex", "asa akira", "ass4all",
        "assoass", "babepedia", "babesource", "backroom casting", "backroomcastingcouch",
        "bang bros", "beeg con", "big asses", "blacked raw", "blackedraw", "boob suck",
        "boobyday", "boyfriendtv", "brandi love", "cherie deville", "cliphunter",
        "dadcrush", "desivdo", "daughterswap", "daft sex", "dickdrainers", "dino tube",
        "dinotube", "downblouse", "elephant tube", "eliza ibarra", "emma hix", "eporn",
        "eva notty", "excogi", "faketaxi", "faphouse", "forhertube", "fortnite rule 34",
        "xev bellringer", "woodman casting"
    ]
};
