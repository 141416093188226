import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import './LoginModal.css';
import SignUpModal from './SignUpModal';
import ForgotPasswordModal from './ForgotPasswordModal';

const LoginModal = ({ onClose, onLoginSuccess }) => {
    const [identifier, setIdentifier] = useState(''); // Changed from email to identifier
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);

    const fetchEmailByUsername = async (username) => {
        const db = getFirestore();
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('username', '==', username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            return userDoc.data().email;
        }
        return null;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        try {
            let email = identifier;
            if (!identifier.includes('@')) {
                email = await fetchEmailByUsername(identifier);
                if (!email) {
                    throw new Error('Invalid login credentials. Please try again.');
                }
            }
            await signInWithEmailAndPassword(auth, email, password);
            onLoginSuccess();
        } catch (err) {
            setError('Invalid login credentials. Please try again.');
        }
    };

    if (isSignUp) {
        return <SignUpModal onClose={() => setIsSignUp(false)} onSignUpSuccess={() => setIsSignUp(false)} />;
    }

    if (isForgotPassword) {
        return <ForgotPasswordModal onClose={() => setIsForgotPassword(false)} />;
    }

    return (
        <div className="login-modal-overlay" onClick={onClose}>
            <div className="login-modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="login-modal-header">
                    <h2>Pinkwire</h2>
                </div>
                <form className="login-form" onSubmit={handleLogin}>
                    <div className="login-form-group">
                        <input
                            type="text" // Changed type to text to allow username or email
                            placeholder="Phone number, username, or email"
                            value={identifier}
                            onChange={(e) => setIdentifier(e.target.value)}
                            required
                        />
                    </div>
                    <div className="login-form-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="login-error">{error}</p>}
                    <button type="submit" className="login-button">Log In</button>
                </form>
                <div className="login-separator">
                    <div className="line"></div>
                    <div className="or">OR</div>
                    <div className="line"></div>
                </div>
                <div className="login-forgot-password">
                    <button onClick={() => setIsForgotPassword(true)}>Forgot password?</button>
                </div>
                <div className="login-signup">
                    <p>Don't have an account? <button onClick={() => setIsSignUp(true)}>Sign up</button></p>
                </div>
            </div>
        </div>
    );
};

export default LoginModal;
