import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref, listAll, getDownloadURL, getMetadata, updateMetadata } from "firebase/storage";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAQ8ajG6fz2nydqWuiHzvv6TlHIr0z9c6g",
  authDomain: "myvidstash.firebaseapp.com",
  databaseURL: "https://myvidstash-default-rtdb.firebaseio.com",
  projectId: "myvidstash",
  storageBucket: "myvidstash.appspot.com",
  messagingSenderId: "607708005049",
  appId: "1:607708005049:web:dc2c27e952c26312951168",
  measurementId: "G-H8M0C47Z35"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);  // Add this line to initialize authentication
const storage = getStorage(app);
const firestore = getFirestore(app);

const fetchVideosFromFirebase = async () => {
    const videosRef = ref(storage, 'videos/');
    const thumbnailsRef = ref(storage, 'thumbnails/');

    const result = await listAll(videosRef);

    const fetchPromises = result.items.map(async (item) => {
        const videoURL = await getDownloadURL(item);
        const metadata = await getMetadata(item);
        const videoFileName = item.name;
        const thumbnailFileName = videoFileName.replace(/\.(mov|mp4)$/i, ".jpg");
        const thumbnailRef = ref(thumbnailsRef, thumbnailFileName);
        const thumbnailURL = await getDownloadURL(thumbnailRef);

        // Fetching additional data from Firestore
        const videoDocRef = doc(firestore, `videos/${item.name}`);
        const videoDoc = await getDoc(videoDocRef);
        const videoData = videoDoc.exists() ? videoDoc.data() : {};

        // Prefetch image
        const img = new Image();
        img.src = thumbnailURL;

        return {
            title: videoFileName.split('.')[0],
            videoURL,
            thumbnailURL,
            views: metadata.customMetadata?.views || 0, // Assuming views are stored in custom metadata
            videoId: item.fullPath, // Adding videoId to track views
            created: videoData.Created ? new Date(videoData.Created.seconds * 1000) : new Date(), // Convert Firestore timestamp to JS Date
            likes: videoData.likes || 0
        };
    });

    return Promise.all(fetchPromises);
};

const incrementVideoViews = async (videoId) => {
    const videoRef = ref(storage, videoId);
    const metadata = await getMetadata(videoRef);
    const views = parseInt(metadata.customMetadata?.views || '0') + 1;
    await updateMetadata(videoRef, {
        customMetadata: {
            views: views.toString()
        }
    });
};

const fetchVideoMetadataFromFirebase = async () => {
    const storageRef = ref(storage, 'videos/');
    const result = await listAll(storageRef);
    return result.items.map(item => ({
        name: item.name,
        fullPath: item.fullPath,
    }));
};

const fetchThumbnailsFromFirebase = async () => {
    const storageRef = ref(storage, 'videos/');
    const result = await listAll(storageRef);

    const fetchThumbnailPromises = result.items.map(async (item) => {
        const videoFileName = item.name;
        const thumbnailFileName = videoFileName.replace(/\.(mov|mp4)$/i, ".jpg");
        const thumbnailRef = ref(storage, `thumbnails/${thumbnailFileName}`);
        const thumbnailURL = await getDownloadURL(thumbnailRef);
        return { thumbnailURL };
    });

    return Promise.all(fetchThumbnailPromises);
};

export { auth, fetchThumbnailsFromFirebase, fetchVideoMetadataFromFirebase, fetchVideosFromFirebase, incrementVideoViews };
