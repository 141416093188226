// src/components/MobileVideoGrid.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchVideosFromFirebase } from '../firebase';
import './MobileVideoGrid.css';

const MobileVideoGrid = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVideos = async () => {
            const fetchedVideos = await fetchVideosFromFirebase();
            setVideos(fetchedVideos);
            setLoading(false);
        };
        fetchVideos();
    }, []);

    const handleVideoClick = (videoId) => {
        navigate(`/mobile/${videoId}`);
    };

    return (
        <div className="mobile-video-grid">
            {loading ? (
                <p>Loading...</p>
            ) : (
                videos.map((video, index) => (
                    <div key={index} className="mobile-video-card" onClick={() => handleVideoClick(video.videoId)}>
                        <img src={video.thumbnailURL} alt={video.title} className="mobile-video-thumbnail" />
                    </div>
                ))
            )}
        </div>
    );
};

export default MobileVideoGrid;
