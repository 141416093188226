import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, arrayUnion, arrayRemove, setDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import './MobileVideoPage.css';
import { formatDistanceToNow } from 'date-fns'; // To format the timestamp
import { FaHeart, FaRegHeart, FaRegComment, FaRegPaperPlane, FaRegBookmark, FaBookmark } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';

const MobileVideoPage = () => {
    const { videoId } = useParams();
    const [video, setVideo] = useState(null);
    const [videoURL, setVideoURL] = useState("");
    const [likes, setLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [isFavorited, setIsFavorited] = useState(false);
    const [comments, setComments] = useState([]);
    const videoRef = useRef(null);

    // Memoize the checkIfFavorited function to avoid redefinition in every render
    const checkIfFavorited = useCallback(async (userId) => {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', userId);

        try {
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists) {
                const data = userDoc.data();
                if (data.favorites && data.favorites.includes(videoId)) {
                    setIsFavorited(true);
                }
            }
        } catch (error) {
            console.error('Error checking if favorited:', error);
        }
    }, [videoId]);

    // Move createVideoDocument outside useEffect and memoize it with useCallback
    const createVideoDocument = useCallback(async () => {
        const db = getFirestore();
        const videoDocRef = doc(db, 'videos', videoId);
        
        try {
            await setDoc(videoDocRef, {
                likes: 0,
                likedBy: [],
                comments: []
            });
        } catch (error) {
            console.error('Error creating video document:', error);
        }
    }, [videoId]);

    useEffect(() => {
        const fetchVideo = async () => {
            const db = getFirestore();
            const videoDocRef = doc(db, 'videos', videoId);

            try {
                const videoDoc = await getDoc(videoDocRef);
                if (videoDoc.exists) {
                    const videoData = videoDoc.data();
                    setVideo(videoData);
                    setLikes(videoData.likes || 0);
                    setComments(videoData.comments || []);

                    const auth = getAuth();
                    if (auth.currentUser) {
                        const isUserLiked = videoData.likedBy?.includes(auth.currentUser.uid) || false;
                        setIsLiked(isUserLiked);
                        checkIfFavorited(auth.currentUser.uid);
                    }

                    const storage = getStorage();
                    const videoStorageRef = ref(storage, `videos/${videoId}`);
                    const url = await getDownloadURL(videoStorageRef);
                    setVideoURL(url);
                } else {
                    console.log('No such video!');
                }
            } catch (error) {
                console.error('Error fetching video:', error);
            }
        };

        fetchVideo();
    }, [videoId, checkIfFavorited, createVideoDocument]);

    useEffect(() => {
        if (videoRef.current && videoURL) {
            videoRef.current.play();
        }
    }, [videoURL]);

    const toggleLike = async () => {
        const auth = getAuth();
        if (!auth.currentUser) {
            console.log('User not logged in. Cannot like the video.');
            return;
        }

        const db = getFirestore();
        const videoDocRef = doc(db, 'videos', videoId);

        try {
            if (isLiked) {
                await updateDoc(videoDocRef, {
                    likes: likes - 1,
                    likedBy: arrayRemove(auth.currentUser.uid)
                });
                setLikes(likes - 1);
                setIsLiked(false);
            } else {
                await updateDoc(videoDocRef, {
                    likes: likes + 1,
                    likedBy: arrayUnion(auth.currentUser.uid)
                });
                setLikes(likes + 1);
                setIsLiked(true);
            }
        } catch (error) {
            console.error('Error updating like:', error);
        }
    };

    const toggleFavorite = async () => {
        const auth = getAuth();
        if (!auth.currentUser) {
            console.log('User not logged in. Cannot favorite the video.');
            return;
        }

        const db = getFirestore();
        const userDocRef = doc(db, 'users', auth.currentUser.uid);

        try {
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists) {
                if (isFavorited) {
                    await updateDoc(userDocRef, {
                        favorites: arrayRemove(videoId)
                    });
                    setIsFavorited(false);
                } else {
                    await updateDoc(userDocRef, {
                        favorites: arrayUnion(videoId)
                    });
                    setIsFavorited(true);
                }
            } else {
                await updateDoc(userDocRef, {
                    favorites: [videoId]
                });
                setIsFavorited(true);
            }
        } catch (error) {
            console.error('Error updating favorite:', error);
        }
    };

    if (!video) {
        return <div>Loading...</div>;
    }

    // Safely access the created field, defaulting to current date if it doesn't exist
    const videoCreatedDate = video.created?.seconds ? new Date(video.created.seconds * 1000) : new Date();
    const usernameInitial = video.username?.charAt(0).toUpperCase() || 'A';

    return (
        <div className="mobile-video-page">
            <div className="video-header">
                <div className="user-info">
                    <div className="profile-image-circle">
                        {usernameInitial}
                    </div>
                    <div className="user-details">
                        <span className="username">{video.username || 'Anonymous'}</span>
                    </div>
                </div>
            </div>
            <div className="video-container">
                <video ref={videoRef} src={videoURL} controls playsInline />
            </div>
            <div className="video-interactions">
                <div className="icons-container">
                    {isLiked ? (
                        <FaHeart className="icon liked" onClick={toggleLike} />
                    ) : (
                        <FaRegHeart className="icon" onClick={toggleLike} />
                    )}
                    <FaRegComment className="icon" />
                    <FaRegPaperPlane className="icon" />
                    {isFavorited ? (
                        <FaBookmark className="icon favorited" onClick={toggleFavorite} />
                    ) : (
                        <FaRegBookmark className="icon" onClick={toggleFavorite} />
                    )}
                </div>
                <div className="likes">
                    <span>{likes} likes</span>
                </div>
                <div className="comments">
                    <span>View all {comments.length} comments</span>
                </div>
                <div className="video-date">
                    <span>{formatDistanceToNow(videoCreatedDate)} ago</span>
                </div>
            </div>
        </div>
    );
};

export default MobileVideoPage;
