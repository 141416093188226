import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './SupportModal.css';

const SupportModal = ({ onClose }) => {
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            message,
            email,
        };

        emailjs.send('service_4pk74io', 'template_ljzqo8o', templateParams, 'd3kOkrMpr65f5LmzL')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully.');
                onClose();
            }, (err) => {
                console.error('FAILED...', err);
                alert('Failed to send message.');
            });
    };

    return (
        <div className="support-modal-overlay" onClick={onClose}>
            <div className="support-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="support-close-button" onClick={onClose}>X</button>
                <h2 className="support-modal-title">Support and Contact Information</h2>
                <form onSubmit={handleSubmit}>
                    <div className="support-form-group">
                        <label>Your message (*)</label>
                        <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="please tell us your impressions about our website, report bugs and propose new features"
                            required
                        ></textarea>
                    </div>
                    <div className="support-form-group">
                        <label>Email (optional)</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="optional, use this field if you want us to reply"
                        />
                    </div>
                    <button type="submit" className="support-send-button">SEND</button>
                </form>
            </div>
        </div>
    );
};

export default SupportModal;
