import React, { useState, useRef } from 'react';
import { FaPlay } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import './VideoCard.css';
import { incrementVideoViews } from '../firebase';
import VideoModal from './VideoModal';
import keywords from './keywords';
import { Helmet } from 'react-helmet';

const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

const VideoCard = ({ title, videoURL, thumbnailURL, duration, views, videoId }) => {
    const [isPlaying] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [hover, setHover] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const videoRef = useRef();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    const handlePlayVideo = () => {
        setShowModal(true);
        incrementVideoViews(videoId);
    };

    const allKeywords = [
        ...keywords.primary,
        ...keywords.secondary,
        ...keywords.longTail
    ];

    return (
        <div
            ref={ref}
            className="video-card"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Helmet>
                <title>{`Pinkwire - ${allKeywords.join(', ')}`}</title>
                <meta name="description" content={`Watch ${title} and more videos on ${allKeywords.join(', ')}.`} />
                <meta name="keywords" content={allKeywords.join(', ')} />
            </Helmet>
            {showModal && (
                <VideoModal
                    video={{ title, videoURL, views, videoId }}
                    onClose={() => setShowModal(false)}
                />
            )}
            {isPlaying ? (
                <video
                    ref={videoRef}
                    className="video-thumbnail"
                    src={videoURL}
                    controls
                    autoPlay
                    muted
                    preload="metadata"
                />
            ) : (
                <div className="thumbnail-container" onClick={handlePlayVideo}>
                    {inView && (
                        <img
                            src={thumbnailURL}
                            alt={`${title} ${allKeywords.join(', ')}`}
                            className="video-thumbnail"
                            onLoad={() => setImageLoaded(true)}
                            style={{ display: imageLoaded ? 'block' : 'none' }}
                        />
                    )}
                    {!imageLoaded && (
                        <div className="thumbnail-placeholder">
                        </div>
                    )}
                    {duration && (
                        <span className="video-duration">
                            {formatDuration(duration)}
                        </span>
                    )}
                    <FaPlay className="play-button-icon" />
                    {hover && (
                        <div className="hover-overlay">
                            <span>{views} views</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default VideoCard;
