// src/components/VideoGrid.js
import React, { useState, useEffect } from 'react';
import VideoCard from './VideoCard';
import { fetchThumbnailsFromFirebase, fetchVideosFromFirebase } from '../firebase';
import './VideoGrid.css';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import keywords from './keywords';
import { Helmet } from 'react-helmet';


const VideoGrid = ({ selectedTab }) => {
    const [videos, setVideos] = useState([]);
    const [thumbnails, setThumbnails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [favorites, setFavorites] = useState([]);
    const [user, setUser] = useState(null);

    const allKeywords = [
        ...keywords.primary,
        ...keywords.secondary,
        ...keywords.longTail
    ];

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            if (user) {
                fetchFavorites(user);
            }
        });

        const fetchThumbnails = async () => {
            const fetchedThumbnails = await fetchThumbnailsFromFirebase();
            setThumbnails(fetchedThumbnails);
        };

        const fetchVideos = async () => {
            const fetchedVideos = await fetchVideosFromFirebase();
            setVideos(fetchedVideos);
            setLoading(false);
        };

        const fetchFavorites = async (user) => {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setFavorites(userData.favorites || []);
            } else {
                // Handle the case where the user document does not exist
                setFavorites([]);
            }
        };

        fetchThumbnails().then(fetchVideos);

        return () => unsubscribe();
    }, []);



    const sortedVideos = () => {
        console.log("Original videos:", videos);

        if (selectedTab === "LATEST") {
            const sortedByLatest = [...videos].sort((a, b) => {
                const dateA = new Date(a.created);
                const dateB = new Date(b.created);
                console.log(`Comparing dates: ${dateA} and ${dateB}`);
                return dateB - dateA;
            });
            console.log("Sorted by latest:", sortedByLatest);
            return sortedByLatest;
        } else if (selectedTab === "TOP RATED") {
            const sortedByLikes = [...videos].sort((a, b) => {
                console.log(`Comparing likes: ${a.likes} and ${b.likes}`);
                return b.likes - a.likes;
            });
            console.log("Sorted by top rated:", sortedByLikes);
            return sortedByLikes;
        } else if (selectedTab === "MOST VIEWED") {
            const sortedByViews = [...videos].sort((a, b) => {
                console.log(`Comparing views: ${a.views} and ${b.views}`);
                return b.views - a.views;
            });
            console.log("Sorted by most viewed:", sortedByViews);
            return sortedByViews;
        } else if (selectedTab === "FAVORITES") {
            if (!user) {
                return [];
            }
            const filteredFavorites = videos.filter((video) => favorites.includes(video.videoId));
            console.log("Filtered favorites:", filteredFavorites);
            return filteredFavorites;
        }
        return videos;
    };

    return (
        <div className="video-grid">
            <Helmet>
                <title>{`Pinkwire: Browse Videos: ${selectedTab} | ${allKeywords.join(', ')}`}</title>
                <meta name="description" content={`Explore our collection of videos under the ${selectedTab} category. Watch videos on ${allKeywords.join(', ')} and more.`} />
            </Helmet>
            {loading ? (
                thumbnails.map((thumbnail, index) => (
                    <div key={index} className="thumbnail-placeholder">
                        <img src={thumbnail.thumbnailURL} alt={`Loading ${allKeywords.join(', ')}...`} className="thumbnail-placeholder-image" />
                    </div>
                ))
            ) : (
                sortedVideos().map((video, index) => (
                    <VideoCard
                        key={index}
                        title={video.title}
                        videoURL={video.videoURL}
                        thumbnailURL={video.thumbnailURL}
                        duration={video.duration}
                        views={video.views}
                        videoId={video.videoId}
                        likes={video.likes} // Pass likes to VideoCard
                        created={video.created} // Pass created date to VideoCard
                    />
                ))
            )}
        </div>
    );
};

export default VideoGrid;
