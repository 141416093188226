import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import './ForgotPasswordModal.css';

const ForgotPasswordModal = ({ onClose }) => {
    const [identifier, setIdentifier] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const fetchEmailByUsername = async (username) => {
        const db = getFirestore();
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('username', '==', username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            return userDoc.data().email;
        }
        return null;
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        try {
            let email = identifier;
            if (!identifier.includes('@')) {
                email = await fetchEmailByUsername(identifier);
                if (!email) {
                    throw new Error('We cannot find an account with that username.');
                }
            }
            console.log(`Sending password reset email to: ${email}`); // Add logging
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent! Check your inbox.');
        } catch (err) {
            console.error('Error sending password reset email:', err); // Add logging
            setError(err.message);
        }
    };

    return (
        <div className="forgot-password-modal-overlay" onClick={onClose}>
            <div className="forgot-password-modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="forgot-password-modal-header">
                    <h2>Trouble Logging In?</h2>
                </div>
                <p className="forgot-password-instructions">
                    Enter your username or email and we'll send you a link to get back into your account.
                </p>
                <form className="forgot-password-form" onSubmit={handleResetPassword}>
                    <div className="forgot-password-form-group">
                        <input
                            type="text"
                            placeholder="Email, Phone, or Username"
                            value={identifier}
                            onChange={(e) => setIdentifier(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="forgot-password-error">{error}</p>}
                    {message && <p className="forgot-password-message">{message}</p>}
                    <button type="submit" className="forgot-password-button">Send Login Link</button>
                </form>
                <div className="forgot-password-separator">
                    <div className="line"></div>
                    <div className="or">OR</div>
                    <div className="line"></div>
                </div>
                <div className="forgot-password-create-account">
                    <p>Create New Account</p>
                </div>
                <div className="forgot-password-back-to-login">
                    <p onClick={onClose}>Back To Login</p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordModal;
