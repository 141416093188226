import React from 'react';
import './TabNavigation.css';
import { FaHome } from 'react-icons/fa'; // Import FontAwesome Home icon

const TabNavigation = ({ selectedTab, onSelectTab }) => {
    const tabs = ["HOME", "LATEST", "TOP RATED", "MOST VIEWED", "FAVORITES"];

    return (
        <ul className="tab-navigation">
            {tabs.map((tab) => (
                <li
                    key={tab}
                    className={selectedTab === tab ? "selected" : ""}
                    onClick={() => onSelectTab(tab)}
                >
                    {tab === "HOME" ? <FaHome className="home-icon" /> : tab}
                </li>
            ))}
        </ul>
    );
};

export default TabNavigation;
