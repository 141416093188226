import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FaTimes, FaRegHeart, FaHeart, FaRegComment, FaRegPaperPlane, FaRegBookmark, FaBookmark, FaSmile } from 'react-icons/fa';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, updateDoc, arrayUnion, arrayRemove, getDoc, setDoc } from 'firebase/firestore';
import LoginModal from './LoginModal';
import EmojiPicker from 'emoji-picker-react';
import './VideoModal.css';
import { formatDistanceToNow } from 'date-fns';
import { Helmet } from 'react-helmet';
import keywords from './keywords';

const VideoModal = ({ video, onClose }) => {
    const videoRef = useRef();
    const previewVideoRef = useRef();
    const previewRef = useRef();
    const [hoverTime, setHoverTime] = useState(null);
    const [hoverTimeFormatted, setHoverTimeFormatted] = useState("");
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [pendingAction, setPendingAction] = useState(null);
    const [likes, setLikes] = useState(video.likes || 0);
    const [isLiked, setIsLiked] = useState(false);
    const [isFavorited, setIsFavorited] = useState(false);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [selectedKeywords, setSelectedKeywords] = useState([]);

    const toggleLike = useCallback(async () => {
        const auth = getAuth();
        if (!auth.currentUser) {
            console.log('User not logged in. Cannot like the video.');
            return;
        }

        const db = getFirestore();
        const videoDocRef = doc(db, video.videoId);

        try {
            const videoDoc = await getDoc(videoDocRef);

            if (!videoDoc.exists) {
                await setDoc(videoDocRef, {
                    likes: 0,
                    likedBy: []
                });
            }

            if (isLiked) {
                await updateDoc(videoDocRef, {
                    likes: likes - 1,
                    likedBy: arrayRemove(auth.currentUser.uid)
                });
                setLikes(likes - 1);
                setIsLiked(false);
            } else {
                await updateDoc(videoDocRef, {
                    likes: likes + 1,
                    likedBy: arrayUnion(auth.currentUser.uid)
                });
                setLikes(likes + 1);
                setIsLiked(true);
            }
        } catch (error) {
            console.error('Error updating like:', error);
        }
    }, [isLiked, likes, video.videoId]);

    const toggleFavorite = useCallback(async () => {
        const auth = getAuth();
        if (!auth.currentUser) {
            console.log('User not logged in. Cannot favorite the video.');
            return;
        }

        const db = getFirestore();
        const userDocRef = doc(db, 'users', auth.currentUser.uid);

        try {
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists) {
                await setDoc(userDocRef, {
                    favorites: [video.videoId]
                });
                setIsFavorited(true);
                return;
            }

            const userData = userDoc.data();
            if (!userData.favorites) {
                await updateDoc(userDocRef, {
                    favorites: []
                });
            }

            if (isFavorited) {
                await updateDoc(userDocRef, {
                    favorites: arrayRemove(video.videoId)
                });
                setIsFavorited(false);
            } else {
                await updateDoc(userDocRef, {
                    favorites: arrayUnion(video.videoId)
                });
                setIsFavorited(true);
            }
        } catch (error) {
            console.error('Error updating favorite:', error);
        }
    }, [isFavorited, video.videoId]);

    const postComment = useCallback(async () => {
        const auth = getAuth();
        const db = getFirestore();
        const videoDocRef = doc(db, video.videoId);

        if (comment.trim() !== "") {
            const newComment = {
                userId: auth.currentUser.uid,
                username: auth.currentUser.displayName || auth.currentUser.email?.split('@')[0] || 'Anonymous',
                text: comment.trim(),
                timestamp: new Date().toISOString(),
                likedBy: []
            };

            try {
                await updateDoc(videoDocRef, {
                    comments: arrayUnion(newComment)
                });

                setComments((prevComments) => [...prevComments, newComment]);
                setComment("");
            } catch (error) {
                console.error('Error posting comment:', error);
            }
        }
    }, [comment, video.videoId]);

    const toggleCommentLike = useCallback(async (commentIndex) => {
        const auth = getAuth();
        if (!auth.currentUser) {
            console.log('User not logged in. Cannot like the comment.');
            return;
        }

        const db = getFirestore();
        const videoDocRef = doc(db, video.videoId);

        try {
            const videoDoc = await getDoc(videoDocRef);
            if (videoDoc.exists) {
                const data = videoDoc.data();
                const comment = data.comments[commentIndex];

                if (!comment.likedBy) {
                    comment.likedBy = [];
                }

                const isCommentLiked = comment.likedBy.includes(auth.currentUser.uid);

                if (isCommentLiked) {
                    comment.likedBy = comment.likedBy.filter((id) => id !== auth.currentUser.uid);
                } else {
                    comment.likedBy.push(auth.currentUser.uid);
                }

                data.comments[commentIndex] = comment;

                await updateDoc(videoDocRef, {
                    comments: data.comments
                });

                setComments(data.comments);
            }
        } catch (error) {
            console.error('Error toggling comment like:', error);
        }
    }, [video.videoId]);

    const shareVideo = useCallback(() => {
        const videoLink = `${window.location.origin}/${video.videoId}`;
        navigator.clipboard.writeText(videoLink).then(() => {
            alert(`Link to this video copied to clipboard: ${videoLink}`);
        }).catch((error) => {
            console.error('Error copying link to clipboard:', error);
        });
    }, [video.videoId]);

    const performAction = useCallback(
        (action, commentIndex = null) => {
            if (action === 'like') {
                toggleLike();
            }
            if (action === 'comment') {
                postComment();
            }
            if (action === 'likeComment') {
                toggleCommentLike(commentIndex);
            }
            if (action === 'favorite') {
                toggleFavorite();
            }
            if (action === 'share') {
                shareVideo();
            }
        },
        [toggleLike, postComment, toggleCommentLike, toggleFavorite, shareVideo]
    );

    const ensureVideoDocument = useCallback(async () => {
        const db = getFirestore();
        const videoDocRef = doc(db, video.videoId);

        try {
            const videoDoc = await getDoc(videoDocRef);
            if (!videoDoc.exists || !videoDoc.data()) {
                await setDoc(videoDocRef, {
                    likes: 0,
                    likedBy: [],
                    comments: []
                });
            } else {
                const data = videoDoc.data();
                if (!data.likedBy) {
                    await updateDoc(videoDocRef, {
                        likedBy: []
                    });
                }
                if (!data.comments) {
                    await updateDoc(videoDocRef, {
                        comments: []
                    });
                }
            }
        } catch (error) {
            console.error('Error ensuring video document:', error);
        }
    }, [video.videoId]);

    const fetchComments = useCallback(async () => {
        const db = getFirestore();
        const videoDocRef = doc(db, video.videoId);

        try {
            const videoDoc = await getDoc(videoDocRef);
            if (videoDoc.exists) {
                const data = videoDoc.data();
                if (data && data.comments) {
                    setComments(data.comments);
                }
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    }, [video.videoId]);

    const checkIfLiked = useCallback(async (userId) => {
        const db = getFirestore();
        const videoDocRef = doc(db, video.videoId);

        try {
            const videoDoc = await getDoc(videoDocRef);
            if (videoDoc.exists) {
                const data = videoDoc.data();
                if (data && data.likedBy && data.likedBy.includes(userId)) {
                    setIsLiked(true);
                }
            }
        } catch (error) {
            console.error('Error checking if liked:', error);
        }
    }, [video.videoId]);

    const checkIfFavorited = useCallback(async (userId) => {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', userId);

        try {
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists) {
                const data = userDoc.data();
                if (data && data.favorites && data.favorites.includes(video.videoId)) {
                    setIsFavorited(true);
                }
            }
        } catch (error) {
            console.error('Error checking if favorited:', error);
        }
    }, [video.videoId]);

    useEffect(() => {
        const allKeywords = [
            ...keywords.primary,
            ...keywords.secondary,
            ...keywords.longTail
        ];

        const shuffledKeywords = allKeywords.sort(() => 0.5 - Math.random());
        setSelectedKeywords(shuffledKeywords.slice(0, 25));

        if (videoRef.current) {
            videoRef.current.play();
        }
        if (previewVideoRef.current) {
            previewVideoRef.current.src = video.videoURL;
        }

        ensureVideoDocument();
        fetchComments();

        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && pendingAction) {
                performAction(pendingAction.action, pendingAction.commentIndex);
                setPendingAction(null);
            }
            if (user) {
                checkIfLiked(user.uid);
                checkIfFavorited(user.uid);
            }
        });

        return () => unsubscribe();
    }, [video, pendingAction, ensureVideoDocument, fetchComments, performAction, checkIfLiked, checkIfFavorited]);

    const handleMouseMove = (e) => {
        const videoRect = videoRef.current.getBoundingClientRect();
        const timelineHeight = 30; // Height of the timeline/progress bar area
        const yOffset = videoRect.bottom - e.clientY;

        // Only generate preview when hovering near the timeline
        if (yOffset <= timelineHeight) {
            const x = e.clientX - videoRect.left;
            const width = videoRect.width;
            const duration = videoRef.current.duration;
            const time = (x / width) * duration;

            if (!isNaN(time) && isFinite(time) && time >= 0 && time <= duration) {
                setHoverTime(time);
                const minutes = Math.floor(time / 60);
                const seconds = Math.floor(time % 60);
                setHoverTimeFormatted(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
                generatePreview(time);
            }
        } else {
            setHoverTime(null);
            setHoverTimeFormatted("");
        }
    };

    const generatePreview = (time) => {
        if (previewVideoRef.current && previewRef.current) {
            // Make sure preview video has loaded
            if (previewVideoRef.current.readyState >= 2) {
                previewVideoRef.current.currentTime = time;
                
                // Use requestAnimationFrame to ensure the currentTime has updated
                requestAnimationFrame(() => {
                    const context = previewRef.current.getContext('2d');
                    context.drawImage(
                        previewVideoRef.current,
                        0, 0,
                        previewRef.current.width,
                        previewRef.current.height
                    );
                });
            }
        }
    };

    const handleMouseLeave = () => {
        setHoverTime(null);
        setHoverTimeFormatted("");
    };

    const checkAuth = async (action, commentIndex = null) => {
        const auth = getAuth();
        if (!auth.currentUser) {
            setShowLoginModal(true);
            setPendingAction({ action, commentIndex });
        } else {
            performAction(action, commentIndex);
        }
    };

    const handleCloseLoginModal = async () => {
        const auth = getAuth();
        await signOut(auth);
        setShowLoginModal(false);
    };

    const handleLoginSuccess = () => {
        setShowLoginModal(false);
        if (pendingAction) {
            performAction(pendingAction.action, pendingAction.commentIndex);
            setPendingAction(null);
        }
    };

    const onEmojiClick = (event) => {
        setComment(comment + event.emoji);
        setShowEmojiPicker(false);
    };

    const handleOverlayClick = () => {
        if (showLoginModal) {
            setShowLoginModal(false);
        } else {
            onClose();
        }
    };

    // Add this useEffect to handle preview video loading
    useEffect(() => {
        if (previewVideoRef.current && video.videoURL) {
            previewVideoRef.current.src = video.videoURL;
            previewVideoRef.current.load(); // Explicitly load the video
        }
    }, [video.videoURL]);

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <Helmet>
                <title>{`Pinkwire - ${selectedKeywords.join(', ')}`}</title>
                <meta name="description" content={`Watch ${video.title} and more videos on ${selectedKeywords.join(', ')}.`} />
                <meta name="keywords" content={selectedKeywords.join(', ')} />
            </Helmet>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>
                    <FaTimes />
                </button>
                <div className="main-content">
                    <div className="video-section">
                        <video
                            ref={videoRef}
                            src={video.videoURL}
                            controls
                            autoPlay
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                        />
                        <video
                            ref={previewVideoRef}
                            style={{ display: 'none' }}
                            preload="auto"  // Add this
                        />
                        {hoverTime !== null && (
                            <div
                                className="preview-image"
                                style={{
                                    position: 'absolute',
                                    bottom: '60px',
                                    left: `${(hoverTime / videoRef.current?.duration) * 100}%`,
                                    transform: ((hoverTime / videoRef.current?.duration) * 100 < 10 || 
                                               (hoverTime / videoRef.current?.duration) * 100 > 90) 
                                              ? 'translate(-50%, -50%)'  // Edge case transform
                                              : 'translate(-50%, -50%)', // Default transform
                                    zIndex: 1000,
                                    marginLeft: ((hoverTime / videoRef.current?.duration) * 100 < 10) ? '80px' : '0',
                                    marginRight: ((hoverTime / videoRef.current?.duration) * 100 > 90) ? '80px' : '0',
                                }}
                            >
                                <canvas 
                                    ref={previewRef} 
                                    width="160" 
                                    height="90"
                                    style={{
                                        border: '2px solid white',
                                        borderRadius: '4px',
                                        backgroundColor: '#000'
                                    }}
                                />
                                <div className="preview-time">{hoverTimeFormatted}</div>
                            </div>
                        )}
                    </div>
                    <div className="details-section">
                        <div className="comments-section">
                            {comments.length === 0 ? (
                                <div className="no-comments-container">
                                    <p className="no-comments">No comments yet.</p>
                                    <p>Start the conversation.</p>
                                </div>
                            ) : (
                                comments.map((comment, index) => (
                                    <div className="comment" key={index}>
                                        <div className="comment-icon">
                                            {comment.username ? comment.username.charAt(0).toUpperCase() : 'A'}
                                        </div>
                                        <div className="comment-content">
                                            <span className="comment-text">{comment.text}</span>
                                            <div className="comment-details">
                                                <span className="comment-time">{formatDistanceToNow(new Date(comment.timestamp))} ago</span>
                                                <span className="comment-likes">{comment.likedBy ? comment.likedBy.length : 0} likes</span>
                                                <span className="comment-reply">Reply</span>
                                            </div>
                                        </div>
                                        {comment.likedBy && comment.likedBy.includes(getAuth().currentUser?.uid) ? (
                                            <FaHeart className="comment-like-icon liked" onClick={() => checkAuth('likeComment', index)} />
                                        ) : (
                                            <FaRegHeart className="comment-like-icon" onClick={() => checkAuth('likeComment', index)} />
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="actions-section">
                            {isLiked ? (
                                <FaHeart className="icon liked" onClick={() => checkAuth('like')} />
                            ) : (
                                <FaRegHeart className="icon" onClick={() => checkAuth('like')} />
                            )}
                            <FaRegComment className="icon" onClick={() => checkAuth('comment')} />
                            <FaRegPaperPlane className="icon" onClick={() => checkAuth('share')} />
                            {isFavorited ? (
                                <FaBookmark className="icon favorited" onClick={() => checkAuth('favorite')} />
                            ) : (
                                <FaRegBookmark className="icon" onClick={() => checkAuth('favorite')} />
                            )}
                        </div>
                        <div className="add-comment-section">
                            <FaSmile className="emoji-icon" onClick={() => setShowEmojiPicker(!showEmojiPicker)} />
                            {showEmojiPicker && <EmojiPicker onEmojiClick={onEmojiClick} />}
                            <input
                                type="text"
                                placeholder="Add a comment..."
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                            {comment.trim() !== "" && (
                                <span className="post-button" onClick={() => checkAuth('comment')}>Post</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showLoginModal && (
                <LoginModal
                    onClose={handleCloseLoginModal}
                    onLoginSuccess={handleLoginSuccess}
                />
            )}
        </div>
    );
};

export default VideoModal;
