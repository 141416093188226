// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VideoGrid from './components/VideoGrid';
import TabNavigation from './components/TabNavigation';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import './App.css';
import { FaUserCircle } from 'react-icons/fa';
import SupportModal from './components/SupportModal';
import LoginModal from './components/LoginModal';
import VideoPage from './components/VideoPage';
import keywords from './components/keywords';
import { Helmet } from 'react-helmet';
import MobileVideoGrid from './components/MobileVideoGrid';
import MobileVideoPage from './components/MobileVideoPage';
import { useMediaQuery } from 'react-responsive';

function App() {
    const [selectedTab, setSelectedTab] = useState("HOME");
    const [showDropdown, setShowDropdown] = useState(false);
    const [user, setUser] = useState(null);
    const [showSupportModal, setShowSupportModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [pendingAction, setPendingAction] = useState(null);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const allKeywords = [
        ...keywords.primary,
        ...keywords.secondary,
        ...keywords.longTail
    ];

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            if (user && pendingAction) {
                performPendingAction();
                setPendingAction(null);
            }
        });

        return () => unsubscribe();
    }, [pendingAction]);

    const handleIconClick = () => {
        if (user) {
            setShowDropdown(!showDropdown);
        } else {
            setShowLoginModal(true);
        }
    };

    const handleLogout = async () => {
        const auth = getAuth();
        await signOut(auth);
        setShowDropdown(false);
    };

    const getUserInitial = () => {
        if (user) {
            if (user.displayName) {
                return user.displayName.charAt(0).toUpperCase();
            } else if (user.email) {
                return user.email.charAt(0).toUpperCase();
            }
        }
        return 'U';
    };

    const handleSupportClick = () => {
        setShowSupportModal(true);
    };

    const performPendingAction = () => {
        // Currently, no pending actions
    };

    const handleLoginSuccess = () => {
        setShowLoginModal(false);
        if (pendingAction) {
            performPendingAction();
            setPendingAction(null);
        }
    };

    return (
        <Router>
            <Helmet>
                <title>{`Pinkwire: Watch Videos on ${allKeywords.join(', ')}`}</title>
                <meta name="description" content={`Watch videos on ${allKeywords.join(', ')} and more. Enjoy our extensive collection of high-quality videos on various topics.`} />
                <meta name="keywords" content={allKeywords.join(', ')} />
            </Helmet>
            <div className="App">
                <div className="header">
                    <div className="logo-container">
                        <div className="logo">
                            <span className="spank">PINK</span><span className="wire">WIRE</span>
                        </div>
                    </div>
                    <div className="search-bar">
                        <input type="text" placeholder={`Search ${allKeywords.join(', ')}...`} />
                    </div>
                    {user ? (
                        <div className="user-icon" onClick={handleIconClick}>
                            <span className="user-initial">{getUserInitial()}</span>
                            {showDropdown && (
                                <div className="dropdown-menu">
                                    <ul>
                                        <li>Settings</li>
                                        <li>Your activity</li>
                                        <li>Saved</li>
                                        <li>Switch appearance</li>
                                        <li>Report a problem</li>
                                        <li>Switch accounts</li>
                                        <li onClick={handleLogout}>Log out</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="sign-in-button" onClick={() => setShowLoginModal(true)}>
                            <FaUserCircle size={24} color="#007BFF" />
                            <span className="sign-in-text">Sign in</span>
                        </div>
                    )}
                </div>
                <div className="tab-navigation-container">
                    <TabNavigation selectedTab={selectedTab} onSelectTab={setSelectedTab} />
                </div>
                <div className="banner-ad-top">
                    <h2>Ad Banner</h2>
                    <p>Your Ad Here</p>
                </div>
                <Routes>
                    <Route path="/videos/:videoId" element={<VideoPage />} />
                    <Route path="/mobile/videos/:videoId" element={<MobileVideoPage />} />
                    <Route path="/" element={
                        isMobile ? (
                            <MobileVideoGrid />
                        ) : (
                            <div className="video-grid-container">
                                <div className="banner-ad left-banner">
                                    <h2>Ad Banner</h2>
                                    <p>Your Ad Here</p>
                                </div>
                                <div className="video-grid-wrapper">
                                    <VideoGrid selectedTab={selectedTab} />
                                </div>
                                <div className="banner-ad right-banner">
                                    <h2>Ad Banner</h2>
                                    <p>Your Ad Here</p>
                                </div>
                            </div>
                        )
                    } />
                </Routes>
                <button className="support-button-fixed" onClick={handleSupportClick}>Support</button>
                {showSupportModal && <SupportModal onClose={() => setShowSupportModal(false)} />}
                {showLoginModal && <LoginModal onClose={() => setShowLoginModal(false)} onLoginSuccess={handleLoginSuccess} />}
            </div>
        </Router>
    );
}

export default App;
