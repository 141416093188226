import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const VideoPage = () => {
    const { videoId } = useParams();
    const [video, setVideo] = useState(null);
    const [videoURL, setVideoURL] = useState("");
    const videoRef = useRef(null);

    useEffect(() => {
        const fetchVideo = async () => {
            const db = getFirestore();
            const videoDocRef = doc(db, 'videos', videoId);

            try {
                const videoDoc = await getDoc(videoDocRef);
                if (videoDoc.exists) {
                    setVideo(videoDoc.data());
                    // Assume videoId is the path to the video file in storage
                    const storage = getStorage();
                    const videoStorageRef = ref(storage, `videos/${videoId}`);
                    const url = await getDownloadURL(videoStorageRef);
                    setVideoURL(url);
                } else {
                    console.log('No such video!');
                }
            } catch (error) {
                console.error('Error fetching video:', error);
            }
        };

        fetchVideo();
    }, [videoId]);

    useEffect(() => {
        if (videoRef.current && videoURL) {
            videoRef.current.play();
        }
    }, [videoURL]);

    if (!video) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>{video.title}</h1>
            <video ref={videoRef} src={videoURL} controls autoPlay />
        </div>
    );
};

export default VideoPage;
