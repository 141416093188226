import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import './SignUpModal.css';

const SignUpModal = ({ onClose, onSignUpSuccess }) => {
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSignUp = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        const db = getFirestore();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Create a document in Firestore with the user's email and username
            await setDoc(doc(db, 'users', user.uid), {
                email: email,
                username: username,
                fullName: fullName
            });

            await sendEmailVerification(user);
            auth.signOut(); // Ensure the user is signed out after signup
            onSignUpSuccess();
        } catch (err) {
            setError('Error signing up. Please try again.');
        }
    };

    return (
        <div className="signup-modal-overlay" onClick={onClose}>
            <div className="signup-modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="signup-modal-header">
                    <h2>Pinkwire</h2>
                </div>
                <form className="signup-form" onSubmit={handleSignUp}>
                    <div className="signup-form-group">
                        <input
                            type="email"
                            placeholder="Mobile Number or Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="signup-form-group">
                        <input
                            type="text"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="signup-form-group">
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            autoComplete="off" // Disable auto-complete for username
                        />
                    </div>
                    <div className="signup-form-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            autoComplete="new-password" // Disable auto-complete for password
                        />
                    </div>
                    {error && <p className="signup-error">{error}</p>}
                    <button type="submit" className="signup-button">Sign Up</button>
                </form>
                <div className="signup-separator">
                    <div className="line"></div>
                    <div className="or">OR</div>
                    <div className="line"></div>
                </div>
                <div className="signup-login">
                    <p>Have an account? <button href="#" onClick={onClose}>Log in</button></p>
                </div>
            </div>
        </div>
    );
};

export default SignUpModal;
